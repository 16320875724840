.welcome-headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 80%;
  span {
    opacity: 1;
    background-color: white;
    --start-cut: 9vw;
    --end-cut: 31vw;
    position: absolute;
    left: 25%;
    top: 1.5vw;
    width: 50%;
    height: 3px;
    display: block;
    border-radius: 5px;
    background: linear-gradient(
      to right,
      white 0%,
      white var(--start-cut),
      transparent var(--start-cut),
      transparent var(--end-cut),
      white var(--end-cut),
      white 100%
    );

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
  .smaller-line {
    line-height: 1.1 !important;
    text-rendering: optimizeSpeed;
  }
  h3 {
    font-size: 3rem;
    font-family: "ProximaNovaThin";
    text-shadow: 3px 2px var(--ourorange);

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
  h2 {
    font-size: 3rem;
    font-family: "ProximaNovaThin";
    margin-top: 2vh;
    text-shadow: 3px 2px var(--ourorange);
  }
  h1 {
    font-size: 7rem;
    font-family: "DeLittleChromaticBold";
    color: var(--ourorange);
    margin-bottom: -0.5rem;
    text-shadow: 6px 5px white;
  }
  @media screen and (max-width: 768px) {
    span {
      opacity: 0.75;
      background-color: white;
      --start-cut: 13vw;
      --end-cut: 56.5vw;
      position: absolute;
      left: 0;
      top: 4vw;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        to right,
        white 0%,
        white var(--start-cut),
        transparent var(--start-cut),
        transparent var(--end-cut),
        white var(--end-cut),
        white 100%
      );
    }
    h3 {
      font-size: 6rem;
    }
    h2 {
      font-size: 2.5rem;
      text-shadow: 2px 2px var(--ourorange);
    }
    h1 {
      font-size: 6rem;
      text-shadow: 5px 4px white;
    }
  }

  @media screen and (max-width: 700px) {
    h1 {
      font-size: 5rem;
      text-shadow: 4px 3px white;
    }

    h2 {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 550px) {
    h1 {
      font-size: 4rem;
      text-shadow: 3px 2px white;
    }

    h2 {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 450px) {
    h1 {
      font-size: 3rem;
      text-shadow: 3px 2px white;
    }

    h2 {
      font-size: 1.2rem;
    }
  }
}

.burger {
  position: fixed;
  width: 4pc;
  height: 4pc;
  top: 1pc;
  left: 1pc;
  @media screen and (min-width: 768px) {
    width: 4rem;
    height: 4rem;
    top: 3rem;
    left: 4.5rem;
  }
  z-index: 999999999;
  cursor: pointer;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.75));
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.75));
}

.burger span {
  position: absolute;
  background-color: white;
  height: 0.3pc;
  border-radius: 6em;
  opacity: 1;
  left: 0;
  transition: all 400ms ease-in-out, background-color 100ms ease-in-out;
}

.burger:hover span {
  @media screen and (min-width: 768px) {
    background-color: #ff6641;
  }
}

.burger span:nth-child(1) {
  top: 10px;
  width: 3.5rem;
}
.burger span:nth-child(2) {
  top: 23px;
  width: 2.7rem;
}
.burger span:nth-child(3) {
  top: 36px;
  width: 1.7rem;
}

.burger.clicked span:nth-child(1) {
  transform-origin: left top;
  top: 10px;
  width: 2.8rem;
  transform: rotate(45deg);
}
.burger.clicked span:nth-child(2) {
  top: 23px;
  width: 0rem;
  opacity: 0;
  transition-duration: 200ms;
}
.burger.clicked span:nth-child(3) {
  transform-origin: left bottom;
  top: 40px;
  width: 2.8rem;
  transform: rotate(-45deg);
}

.phone {
  position: fixed;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.4));
  -webkit-filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.4));
  background-image: url("../img/icon/Telefon.svg");
  background-repeat: no-repeat;
  top: 1pc;
  left: 6pc;
  --size: 3pc;
  @media screen and (min-width: 768px) {
    --size: 3rem;
    top: 3.2rem;
    left: 12rem;
  }
  width: var(--size);
  height: var(--size);
  z-index: 3;
  cursor: grab;
}

.phone:hover {
  animation: phone-ring 600ms ease-in-out;
  @keyframes phone-ring {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(20deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    60% {
      transform: rotate(30deg);
    }
    70% {
      transform: rotate(-15deg);
    }
    80% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.phone-div {
  display: flex;
  flex-direction: row;
  transition: all 150ms ease-in-out;
  div {
    transition: all 150ms ease-in-out;
    opacity: 1;
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
}
.phone-div.hide {
  div {
    opacity: 0;
  }
}

.phone-tooltip {
  opacity: 0;
  position: fixed;
  top: 1.3pc;
  left: 9.2pc;
  @media screen and (min-width: 768px) {
    top: 4rem;
    left: 12.5rem;
  }
  color: black;
  background-color: #e5e7eb;
  font-family: "ProximaNovaLight";
  font-size: x-large;
  border-radius: 2pc;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: 150ms ease-in-out;
  z-index: 3;
}

.phone-div .phone:hover ~ .phone-tooltip {
  opacity: 1;
}
.phone-div.hide .phone:hover ~ .phone-tooltip {
  opacity: 0;
}

.welcome-logo {
  position: absolute;
  top: 3pc;
  left: 50%;
  transform: translateX(-50%);
  width: 5pc;


  @media screen and (min-width: 2000px) {
    width: 5rem;
  }

  @media screen and (max-width: 1300px) {
    width: 3pc;
  }

  @media screen and (max-width: 768px) {
    top: 1pc;
  }

}

.price-tag {
  position: absolute;
  right: -30px;
  top: 20px;
  width: 20vw;

  @media screen and (max-width: 1024px) {
    right: -20px;
  }
}

img.price-tag-image {
  position: relative;
  width: 100%;
  // min-width: 200px;
  z-index: 90;
}

.price-tag-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  top: 20%;
  left: 15%;
  transform: rotate(-17.5deg);
  position: absolute;
  z-index: 100;
}

.price-tag-text {
  font-size: 3.75vw;
  color: white;
  font-family: "ProximaNovaExtraBold";
  width: 100%;
}

.price-tag-text-desc {
  font-size: 1.75vw;
  color: white;
  z-index: 100;
  text-transform: capitalize;
  font-family: "ProximaNovaExtraBold";
  width: 100%;
  margin-top: -1.5vw;
  margin-left: 0.6vw;
}

@media screen and (max-width: 900px) {
  .price-tag {
    right: -25px;
    width: calc(20vw * 1.2);
  }

  .price-tag-text {
    font-size: calc(3.75vw * 1.2);

  }
  
  .price-tag-text-desc {
    font-size: calc(1.75vw * 1.2);

  }
}

@media screen and (max-width: 700px) {
  .price-tag {
    right: -25px;
    width: calc(20vw * 1.4);
  }

  .price-tag-text {
    font-size: calc(3.75vw * 1.4);

  }
  
  .price-tag-text-desc {
    font-size: calc(1.75vw * 1.4);

  }
}

@media screen and (max-width: 600px) {
  .price-tag {
    right: -20px;
    width: calc(20vw * 1.6);
  }

  .price-tag-text {
    font-size: calc(3.75vw * 1.6);

  }
  
  .price-tag-text-desc {
    font-size: calc(1.75vw * 1.6);

  }
}

@media screen and (max-width: 500px) {
  .price-tag {
    right: -15px;
    width: calc(20vw * 1.7);
  }

  .price-tag-text {
    font-size: calc(3.75vw * 1.7);

  }
  
  .price-tag-text-desc {
    font-size: calc(1.75vw * 1.7);

  }
}

.qr-phone {
  position: fixed;
  bottom: -5vw;
  right: 0;
  transform: rotate(-17.25deg);
  transform-origin: center;
  width: 13vw;
  height: auto;
  z-index: 999;
  transition: right 0.5s ease-in-out, transform 0.5s ease-in-out;

  @media  screen and (max-width: 1200px) {
    width: 16vw;
    bottom: -7vw;
  }


  @media  screen and (max-width: 950px) {
    display: none;
  }
}

.qr-phone-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.button-phone {
  position: fixed;
  bottom: -28vw;
  right: -5vw;
  transform: rotate(-17.25deg);
  transform-origin: center;
  width: 25vw;
  height: auto;
  z-index: 888;
  transition: right 0.5s ease-in-out, transform 0.5s ease-in-out;

  
  @media  screen and (min-width: 950px) {
    display: none;
  }

  @media  screen and (max-width: 800px) {
    width: 30vw;
    bottom: -32vw;
    right: -6vw;
  }

  @media  screen and (max-width: 650px) {
    width: 35vw;
    bottom: -38vw;
    right: -7vw;
  }

  @media  screen and (max-width: 550px) {
    width: 40vw;
    bottom: -42vw;
  }

  @media  screen and (max-width: 450px) {
    width: 45vw;
    bottom: -48vw;
  }
}

.button-phone-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.button-phone-description {
  z-index: 999;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: var(--ourorange);
  color: white;
  font-family: ProximaNovaExtraBold;
  font-size: 2.5vw;
  line-height: 2.5vw;
  outline: none;
  border: none;
  
  @media  screen and (max-width: 800px) {
    font-size: 3vw;
    line-height: 3vw;
  }

  @media  screen and (max-width: 650px) {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }

  @media  screen and (max-width: 550px) {
    font-size: 4vw;
    line-height: 3.5vw;
  }

  @media  screen and (max-width: 450px) {
    font-size: 4.5vw;
    line-height: 4.5vw;
  }
}

.button-phone-description:hover, .button-phone-description:active {
  background-color: white !important;
  color: var(--ourorange) !important;
}




.phone-visible {
  right: 0;
}

.phone-hidden {
  transform: rotate(0deg);
  right: -40vh;
}
