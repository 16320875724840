.rotated-banner {
  position: relative;
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  min-height: fit-content;
}

.background-banner {
  position: absolute;
  width: 120%;
  height: 100%;
  background-color: rgba(255, 103, 65, 0.9);
  transform: rotate(-3deg);
  transform-origin: center;
  z-index: 1;
}

.contact-headline {
  font-family: "ProximaNovaExtraBold";
  font-size: 4rem;
  transition: margin-left ease-in-out 100ms;
  color: white;
  text-align: center;
  margin-bottom: 4rem;

  @media screen and (max-width: 1480px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 550px) {
    font-size: 2rem;
  }
}

.contact-content {
  width: 100%;
  height: 50%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  position: relative;
  z-index: 2;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 1780px) {
    width: 80%;
  }

  @media screen and (max-width: 1095px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 700px) {
    width: 95%;
  }
}

.person {
  margin-top: 2rem;

  @media screen and (max-width: 350px) {
    flex: 0 0 auto;
    width: 100%;
  }
}

.personImage {
  width: 250px;
  height: 250px;
  border-radius: 50%;

  @media screen and (max-width: 1000px) {
    width: 200px;
    height: 200px;
  }

  @media screen and (max-width: 650px) {
    width: 150px;
    height: 150px;
  }
}

.big-text {
  font-family: ProximaNovaBold;
  text-align: center;
  color: white;
  display: block;
  font-size: 2.5rem;
  margin-top: 10px;

  @media screen and (max-width: 1080px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 1.7rem;
  }
}

.small-text {
  font-family: ProximaNovaLight;
  text-align: center;
  color: white;
  display: block;
  font-size: 2rem;
  margin-top: -0.5rem;

  @media screen and (max-width: 1080px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 1.2rem;
  }
}

.align-left {
  text-align: left !important;
}

.mail-button {
  border-radius: 0.6pc;
  bottom: 5%;
  left: 50%;
  color: var(--ourorange);
  background-color: white;
  border-color: white;
  font-size: 1.7rem;
  font-family: ProximaNovaBold;
  @media screen and (min-width: 1921px) {
    font-size: 1.5rem;
    border-radius: 0.5vw;
  }

  @media screen and (max-width: 1350px) {
    width: 100%;
  }

  @media screen and (max-width: 1080px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
}

.mail-button:hover {
  color: white;
  background-color: transparent;
  border-color: white;
}

.contactText {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;

  @media screen and (max-width: 1000px) {
    margin-bottom: 0;
  }
}

.phone-button {
  border-radius: 0.6pc;
  bottom: 5%;
  left: 50%;
  color: white;
  background-color: transparent;
  border-color: white;
  font-size: 1.7rem;
  margin-left: 1.5rem;
  font-family: ProximaNovaLight;
  @media screen and (min-width: 1921px) {
    font-size: 1.5vw;
    border-radius: 0.5vw;
  }

  @media screen and (max-width: 1350px) {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
    margin-right: 2rem;
  }

  @media screen and (max-width: 1080px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
}

.phone-button:hover {
  color: var(--ourorange);
  background-color: white;
  border-color: white;
}

.buttonIcons {
  @media screen and (max-width: 1350px) {
    margin-right: 1rem;
  }
}

.buttons {
  margin-top: 2rem;
  text-align: left;
}

.orangeBackground {
  background-color: rgba(255, 103, 65, 0.9);
}
