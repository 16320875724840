@import url("../../assets/scss/common.scss");

.agb-bg {
    position: fixed;
    top: 0;
    z-index: -1;
    overflow: initial;
}

.agb-headline {
    position: fixed;
    top: 0;
    right: 4vw;
    font-size: 4.5vw !important;
    margin-top: 8vw !important;
    z-index: 1;
    text-shadow: black -2px 2px 8px;
}

.agb-holder {
    width: 100%;

    .text {
        max-width: 70%;
        text-align: left;
        color: white;
        white-space: pre-line;
        word-wrap: break-word;
        margin-left: auto;
        margin-right: auto;
        h4 {
            text-align: center;
            font-family: DeLittleChromaticBold;
            font-size: 3vw;
            margin-top: 3pc;
            margin-bottom: 3pc;
        }
        h6 {
            font-family: ProximaNovaBold;
            font-size: 2.5rem;
            margin-top: 3.5rem;
        }
        .spacing {
            margin-top: 5pc;
        }
        padding: 1rem;
        border-radius: 1pc;
        @media screen and (max-width: 768px) {
            h4 {
                font-size: 7vw;
            }
            h6 {
                font-size: 6vw;
                line-height: 6vw;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .text { max-width: 95%; }
    }

    .description {
        font-family: ProximaNovaLight;
        font-size: 1.8rem;
        line-height: 2rem;
        @media screen and (max-width: 768px) {
            font-size: 5vw;
            line-height: 5.2vw;
        }
    }

    a {
        font-size:  1.6rem;
        line-height: 1.75rem;
        @media screen and (max-width: 768px) {
            font-size: 4.8vw;
            line-height: 5vw;
        }
    }

    ul li {
        font-family: ProximaNovaLight;
        font-size: 1.5rem;
        line-height: 1.75rem;
        margin-bottom: 8px;
        @media screen and (max-width: 768px) {
            font-size: 4.7vw;
            line-height: 4.9vw;
        }
    }

    .headline_under {
        font-size: 1.6rem;
        line-height: 1.75rem;
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
            font-size: 4.8vw;
            line-height: 4.915vw;
        }
    }
}

body {
    background-color: #000000 !important;   
}
