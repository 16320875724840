:root {
  --middle-marker-size: 170px;
  --signs-height: 170px;

  @media screen and (max-width: 1400px) {
    --middle-marker-size: 130px;
  }

  @media screen and (max-width: 1150px) {
    --middle-marker-size: 100px;
  }

  @media screen and (max-width: 900px) {
    --middle-marker-size: 70px;
  }

  @media screen and (max-width: 700px) {
    --signs-height: 130px;
  }

  @media screen and (max-width: 500px) {
    --middle-marker-size: 50px;
  }

  @media screen and (max-width: 400px) {
    --middle-marker-size: 40px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background: #f0f0f0;
  overflow-x: hidden;
}

#a-explenation {
  background-color: var(--darkblue);
}

.explanation-content-space {
  position: relative;
  padding-top: 10vw;
}

.last-explanation-bg {
  min-width: 100%;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  min-height: 20%;
}

.explenation-backgrounds {
  position: absolute;
  min-width: 100%;
  height: auto;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);

  .bg {
    min-width: 100%;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    min-height: 20%;
  }
}

.explanation-container {
  width: 90%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30rem;

  @media screen and (max-width: 1400px) {
    padding-bottom: 10rem;
  }
}

.road-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.road {
  stroke: transparent;
  stroke-width: 40px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  //filter: drop-shadow(0 4px 4px rgba(0,0,0,0.2));
}

.road-line {
  stroke: #fff;
  stroke-width: 4px;
  fill: none;
  stroke-dasharray: 20 20;
  //stroke-linecap: round;
}

.content-section {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.content-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 800px;

  @media screen and (max-width: 1400px) {
    max-width: none;
    gap: 0;
    margin: 0 !important;
  }
}

.content-block {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}

.content-block.visible {
  opacity: 1;
  transform: translateY(0);
}

.side-col {
  @media screen and (max-width: 1400px) {
    width: calc(45vw - var(--middle-marker-size));
    //width: calc(50% - var(--middle-marker-size));
  }

  @media screen and (max-width: 500px) {
    padding-left: 5px;
    padding-right: 6px;
  }
}

.image-block {
  width: 100%;
  height: 200px;
  background: #ddd;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.content-section:nth-child(odd) .content-wrapper {
  margin-left: auto;
  margin-right: 15rem;
}

.content-section:nth-child(even) .content-wrapper {
  margin-left: 15rem;
  margin-right: auto;
}

.download-content-wrapper {
  @media screen and (min-width: 1401px) {
    max-width: none !important;
    margin-left: 15rem !important;
    margin-right: 0 !important;
  }
}

.qr-content-wrapper {
  @media screen and (min-width: 1401px) {
    max-width: none !important;
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }
}

.player-content-wrapper {
  margin-right: 0 !important;
}

.rounded-image {
  border-radius: 2rem;
  width: 20rem;
  display: block;

  @media screen and (max-width: 1400px) {
    width: 70%;
  }

  @media screen and (max-width: 1000px) {
    width: 80%;
  }

  @media screen and (max-width: 900px) {
    width: 90%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    border-radius: 1rem;
  }
}

.rounded-image-right {
  @media screen and (max-width: 1400px) {
    margin-left: auto;
  }
}

.rounded-image-left {
  @media screen and (max-width: 1400px) {
    margin-right: auto;
  }
}

.end-info-container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(50% - 500px);
  margin-top: 7px;

  .explenation-title {
    text-align: center;
    display: block;
    margin-top: 1rem;
  }

  .end-rounded-image {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 1400px) {
    padding-left: 0;
  }
}

.explenation-title {
  font-family: ProximaNovaExtraBold;
  font-size: 3.5rem;
  color: var(--ourorange);
  line-height: 3.5rem;
  padding-bottom: 1rem;
  display: inline-block;

  @media screen and (max-width: 1400px) {
    font-size: 3rem;
    line-height: 3rem;
    word-break: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  @media screen and (max-width: 1150px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  @media screen and (max-width: 900px) {
    font-size: 2rem;
    line-height: 2rem;
    padding-bottom: 1rem;
    text-align: left;
  }

  @media screen and (max-width: 650px) {
    font-size: 1.7rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.15rem;
  }
}

.explenation-description {
  color: white;
  font-family: ProximaNovaLight;
  font-size: 1.7rem;
  margin-top: -0.7rem;
  display: block;
  line-height: normal;

  @media screen and (max-width: 1400px) {
    font-size: 1.5rem;
    line-height: normal;
  }

  @media screen and (max-width: 1150px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 900px) {
    font-size: 1.1rem;
    text-align: justify;
  }

  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 0.9rem;
    word-break: break-all;
  }
}

.explenation-button {
  background-color: var(--ourorange);
  font-family: ProximaNovaBold;
  font-size: 2rem;
  color: white;
  border: none;
  margin-top: 1.5rem;

  @media screen and (max-width: 1150px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 900px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 560px) {
    font-size: 0.9rem;
  }
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;

  @media screen and (max-width: 900px) {
    text-align: left;
  }
}

.explenation-button:hover {
  background-color: white;
  color: var(--ourorange);
}

.signs-image {
  height: var(--signs-height) !important;
  width: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: none;

  @media screen and (max-width: 1400px) {
    display: block;
  }
}

#middle-section {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  max-width: 100%;
  margin-top: 150px;
  position: relative;
  z-index: 20;

  img {
    width: 100%;
    height: auto;
  }

  .middle-section-top {
    position: absolute;
    top: 11.5%;
    height: 38.5%;
    width: 60%;
    padding: 4rem;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      font-family: ProximaNovaExtraBold;
      font-size: 3.5rem;
      color: #00aa32;
      line-height: 3rem;
      margin-bottom: 1rem;
    }

    .description {
      font-family: ProximaNovaLight;
      font-size: 1.5rem;
      color: white;
      margin-top: -0.7rem;
      display: block;
      line-height: 2rem;
    }

    .middle-top-button {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 1700px) {
      width: 80%;
    }

    @media screen and (max-width: 1500px) {
      padding: 3rem;
      width: 100%;
      .title {
        font-size: 2.5rem;
      }

      .description {
        font-size: 1.3rem;
      }
    }

    @media screen and (max-width: 1400px) {
      top: calc(5% + var(--signs-height));
    }

    @media screen and (max-width: 850px) {
      padding: 3rem;
      width: 100%;
      .title {
        font-size: 2rem;
      }

      .description {
        font-size: 1.2rem;
      }

      .middle-top-button {
        font-size: 1.3rem;
      }
    }

    @media screen and (max-width: 750px) {
      padding: 3rem;
      width: 100%;
      .title {
        font-size: 1.5rem;
      }

      .description {
        font-size: 1.1rem;
      }

      .middle-top-button {
        font-size: 1.1rem;
      }
    }

    @media screen and (max-width: 450px) {
      padding: 1.5rem;
      width: 100%;
      .title {
        font-size: 1.2rem;
      }

      .description {
        font-size: 1rem;
      }

      .middle-top-button {
        font-size: 1rem;
      }
    }
  }

  .middle-section-bottom {
    position: absolute;
    top: 50%;
    right: 0;
    height: 38.5%;
    width: 60%;
    padding: 4rem;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      font-family: ProximaNovaExtraBold;
      font-size: 3.5rem;
      color: #ffa60d;
      line-height: 3rem;
      margin-bottom: 1rem;
      text-align: right;
    }

    .description {
      font-family: ProximaNovaLight;
      font-size: 1.5rem;
      color: white;
      margin-top: -0.7rem;
      display: block;
      line-height: 2rem;
      text-align: right;
    }

    .middle-top-button {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 1700px) {
      width: 80%;
    }

    @media screen and (max-width: 1500px) {
      padding: 3rem;
      width: 100%;
      .title {
        font-size: 2.5rem;
      }

      .description {
        font-size: 1.3rem;
      }
    }

    @media screen and (max-width: 1400px) {
      top: 55%;
    }

    @media screen and (max-width: 850px) {
      padding: 3rem;
      width: 100%;
      .title {
        font-size: 2rem;
      }

      .description {
        font-size: 1.2rem;
      }

      .middle-top-button {
        font-size: 1.3rem;
      }
    }

    @media screen and (max-width: 750px) {
      padding: 3rem;
      width: 100%;
      .title {
        font-size: 1.5rem;
      }

      .description {
        font-size: 1.1rem;
      }

      .middle-top-button {
        font-size: 1.1rem;
      }
    }

    @media screen and (max-width: 450px) {
      padding: 1.5rem;
      width: 100%;
      .title {
        font-size: 1.2rem;
      }

      .description {
        font-size: 1rem;
      }

      .middle-top-button {
        font-size: 1rem;
      }
    }
  }

  .price-overlay-container {
    position: absolute;
    z-index: 2;

    &.left {
      left: -163px;
      top: 25%;

      @media screen and (max-width: 1400px) {
        top: 35%;
      }
    }

    &.right {
      right: -163px;
      bottom: 25%;

      @media screen and (max-width: 1400px) {
        bottom: 15%;
        
      }
    }

    img {
      max-width: 200px;
    }

    .price-text {
      position: absolute;
      color: white;
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
      transform: rotate(-10deg);
      font-family: proximaNovaBold;

      &.digital-price {
        top: 31%;
        left: 3%;
        transform: rotate(-22.5deg);
        display: block;
        height: 66px;
        width: 105px;
      }

      &.physical-price {
        top: 46%;
        left: 43%;
        transform: rotate(22.5deg);
        display: block;
        height: 66px;
        width: 105px;
      }

      .euro-line {
        font-size: 16px;
        height: 17px;
      }

      .price {
        font-size: 27px;
        height: 25px;
        text-align: left;
        padding-left: 15px;
        margin-top: -4px;
      }

      .price-description {
        font-size: 14px;
        margin-top: 4px;
        text-align: left;
        padding-left: 15px;
      }
    }

    @media screen and (max-width: 1100px) {
      display: none;
    }
  }



  @media screen and (max-width: 1400px) {
    margin-top: 0;
  }

  @media screen and (max-width: 1300px) {
    width: 70%;
  }

  @media screen and (max-width: 1100px) {
    width: 80%;
  }

  @media screen and (max-width: 900px) {
    width: 90%;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.transition-image {
  position: relative;
  bottom: 0;
  left: 0%;
  width: 100%;
  min-width: 1920px;
}

.middle-content-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  padding-left: 15rem;
  padding-right: 15rem;
}

.straight-road-gps-icon {
  margin-left: auto;
  margin-right: auto;
  width: var(--middle-marker-size);
  display: block;
}

.straight-road-middle-col {
  display: none;

  @media screen and (max-width: 1400px) {
    display: block;
    flex-grow: 0;
    padding: 0;
  }
}

.curved-road-gps-icon {
  width: var(--middle-marker-size);
  position: absolute;
  top: 0;

  @media screen and (max-width: 1400px) {
    display: none;
  }
}

.start-icon {
  top: 1rem;
  left: 24px;
}

.gps-icon-left {
  left: 20px;
}

.gps-icon-right {
  right: 20px;
}

.long-content-wrapper {
  max-width: 1000px;
}

#organize-and-instruct {
  margin-top: 8.65rem;
}
.full-width-section {
  max-width: none;
  padding-right: 17rem;
  @media screen and (max-width: 1400px) {
    padding-right: 0;
  }
}

#curvedRoadSvg {
  opacity: 1;

  @media screen and (max-width: 1400px) {
    display: none;
  }
}

#straightRoadSvg {
  opacity: 1;

  @media screen and (min-width: 1401px) {
    display: none;
  }
}

@media (max-width: 1200px) {
  .content-section:nth-child(odd) .content-wrapper {
    margin-right: 300px;
  }
  .content-section:nth-child(even) .content-wrapper {
    margin-left: 300px;
  }
}

@media (max-width: 768px) {
  .road {
    stroke-width: 20px;
  }

  .content-wrapper {
    grid-template-columns: 1fr;
  }

  .content-section:nth-child(odd) .content-wrapper {
    margin-right: 200px;
  }
  .content-section:nth-child(even) .content-wrapper {
    margin-left: 200px;
  }
}
