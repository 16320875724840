@import url("../../assets/scss/common.scss");

html {
    overflow-y: scroll;
}

.blog-content-filler {
    min-height: 100vh;
    width: 100%;
    margin: auto;
    .article {
        color: white;
        display: flex;
        flex-direction: column;
        font-family: ProximaNovaLight;
        align-items: center;
        min-height: 100vh;
        img {
            max-width: 55%;
            min-width: 55%;
            height: 15vw;
            object-fit: cover;
            margin-bottom: 2vw;
            border-radius: 2vw;
        }
        .text {
            width: 55%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-family: ProximaNovaLight;
            font-size: 1.4vw;
            line-height: 1.5vw;
            white-space: pre-wrap;
        }
        h1 {
            font-size: 2vw;
            line-height: 2vw;
            margin-bottom: 1vw;
            font-family: ProximaNovaBold;
        }
    }
    @media screen and (max-width: 768px) {
        .article {
            img {
                max-width: 85%;
                min-width: 85%;
                height: 30vw;
            }
            .text {
                width: 90%;
                font-size: 4.5vw;
                line-height: 4.75vw;
            }
            h1 {
                font-size: 5vw;
                line-height: 5.1vw;
            }
        }       
    }
}

.blog-bg {
    position: fixed;
    top: 0;
    z-index: -1;
    scrollbar-gutter: stable;
    overflow: initial;
}

.blog-headline {
    margin: auto;
    margin-top: 3.5pc;
    margin-bottom: 4pc;
    color: white;
    font-family: DeLittleChromaticBold;
    font-size: 3vw;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 6vw;
        margin-bottom: 1vw;
    }
}

.blog-search-box {
    border-radius: 1vw;
    border: lightgray 1px solid;
    background-color: white;
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 40%;
    height: 5%;
    padding: 0.5vw;
    margin-bottom: 2pc;
    .img-border {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.5vw;
        height: 1.5vw;
        border-left: transparent 1px solid;
        margin-right: 1px;
        pointer-events: none;
    }
    img {
        width: 100%;
        height: 100%;
        transition: opacity 100ms ease-in-out;
        user-select: none;
        user-drag: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        vertical-align: unset;
    }
    input {
        outline: none;
        border: none;
        width: 100%;
        font-size: 1vw;
        font-family: ProximaNovaLight;
        vertical-align: middle;
    }
    &.hide-input input {
        caret-color: transparent;
    }
    &:focus-within {
        .img-border {
            border-left: black 1px solid;
        }
        img {
            opacity: 0.5;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 45%;
        input {
            font-size: 2vw;
        }
        .img-border {
            width: 2vw;
            height: 2vw;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    @media screen and (max-width: 768px) {
        width: 80%;
        input {
            font-size: 5vw;
        }
        .img-border {
            width: 7vw;
            height: 7vw;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.article-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 12px;
    column-gap: 32px;
    margin: auto;
    margin-bottom: 2vw;
    width: 50%;
    min-height: 100dvh;
    .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background-color: rgba($color: #000000, $alpha: 0.85);
        border-radius: 1vw;
        width: 100%;
        height: fit-content;
        border: white 1px solid;
        padding: 1vw;
        color: white;
        h1 {
            font-size: 1.3vw;
            line-height: 1.3vw;
            margin-bottom: 1vw;
            font-family: ProximaNovaBold;
        }
        img {
            max-width: 100%;
            height: 12vw;
            margin: auto;
            margin-bottom: 1vw;
            margin-top: 0;
            border-radius: 1vw;
            object-fit: scale-down;
        }
        p {
            font-family: ProximaNovaLight;
            font-size: 1vw;
            line-height: 1.1vw;
            margin-bottom: 1vw;
        }
        a {
            background-color: var(--ourorange);
            color: black;
            border-radius: 0.5vw;
            text-decoration: none;
            padding: 0.45vw 0.6vw 0.45vw 0.6vw;
            font-size: 1.1vw;
            text-transform: uppercase;
            font-family: ProximaNovaThin;
        }
    }
    @media screen and (max-width: 1200px) {
            .item {
                padding: 1vw;
                border-radius: 2vw;
                h1 {
                    font-size: 2vw;
                    line-height: 2vw;
                    margin-bottom: 1vw;
                }
                img {
                    height: 15vw;
                    border-radius: 2vw;
                }
                p {
                    font-size: 1.5vw;
                    line-height: 1.75vw;
                    margin-bottom: 2vw;
                }
                a {
                    border-radius: 1vw;
                    font-size: 2vw;
                }
            }
    }
    @media screen and (max-width: 768px) {
            width: 90%;
            column-gap: 8px;
            .item {
                padding: 2vw;
                border-radius: 2vw;
                h1 {
                    font-size: 4.5vw;
                    line-height: 5.2vw;
                    margin-bottom: 2vw;
                }
                img {
                    height: 20vw;
                    border-radius: 2vw;
                }
                p {
                    font-size: 3.6vw;
                    line-height: 3.75vw;
                    margin-bottom: 3vw;
                }
                a {
                    border-radius: 1vw;
                    font-size: 4vw;
                }
            }
    }
}