@font-face {
  font-family: "DeLittleChromaticBold";
  src: local("DeLittleChromaticBold"),
    url("../fonts/DeLittle\ Chromatic_Bearbeitet.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaLight";
  src: local("ProximaNovaLight"),
    url("../fonts/Proxima\ Nova\ Condensed\ Light.otf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaBold";
  src: local("ProximaNovaBold"),
    url("../fonts/Proxima\ Nova\ Condensed\ Bold.otf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaExtraBold";
  src: local("ProximaNovaExtraBold"),
    url("../fonts/Proxima\ Nova\ Condensed\ ExtraBold.otf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaThin";
  src: local("ProximaNovaThin"),
    url("../fonts/Proxima\ Nova\ Condensed\ Semibold.otf") format("truetype");
}

* {
  --ourorange: #ff6641;
  --darkblue: #2f385fff;
  --smaller-p: 1.1vw;
  --small-p: 1.2vw;
  --normal-p: 1.4vw;
  --normal-p-1-5: 1.5vw;
  --big-p: 1.8vw;
  // @media screen and (max-width: 1024px) {
  //   --smaller-p: 2.5vw;
  //   --small-p: 4vw;
  //   --normal-p: 4.5vw;
  //   --normal-p-1-5: 5vw;
  //   --big-p: 6vw;
  // }
}

html {
  overflow: hidden;
}
body {
  overflow-y: auto;
  &.modal-open {
    padding-right: 0 !important;
  }
}



p,
h1,
h2,
h3,
h4,
h5 {
  cursor: default;
}

:root {
  --posX: 0;
  --posY: 0;
}

.headline {
  font-family: "ProximaNovaExtraBold";
  font-size: 6vw;
  transition: margin-left ease-in-out 100ms;
  @media screen and (min-width: 768px) {
    font-size: 4vw;
  }
  &.moveRight {
    @media screen and (max-width: 768px) {
      margin-left: 4pc;
    }
  }
  color: white;
  text-align: center;
  &.lower {
    margin-top: 2vw;
  }
}

.headline-text-shadow {
  text-shadow: black 4px 0 7px;
}

.sub-headline {
  font-family: "ProximaNovaExtraBold";
  font-size: 2.5vw;
  color: var(--darkblue);
}

.spread-evenly-vertically {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
}

.info_icon {
  --box-size: 24px;
  max-width: 24px;
  max-height: 24px;
  margin: 0.5pc 0.2pc 0.5pc 0pc;
  cursor: help;
  &.bigger {
    margin: 8px;
    width: 5vw;
    max-width: none;
    max-height: none;
  }
  img {
    max-height: calc(var(--box-size) * 0.6);
    background-color: transparent !important;
  }
  &.img {
    width: var(--box-size);
    height: var(--box-size);
    min-width: var(--box-size);
    max-height: var(--box-size);
    background-color: var(--darkblue) !important;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 1921px) {
    max-width: 42px;
    max-height: 42px;
    margin: 1pc 0.5pc 1pc 0pc;
  }
}

.slider_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slider_labels {
  display: inline-flex;
  width: 100%;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  font-family: ProximaNovaLight;
  font-size: 1vw;
}
.slider_labels li {
  flex: 1 1;
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  //width: 12vw;
  padding-left: 0.35rem; // These magic numbers must be fixed in some better way.
  padding-right: 0.35rem; // required to make the labels underneath the slider look like they are directly underneath each step.
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  background-color: var(--ourorange);
  border-radius: 1rem;
  height: 0.2rem;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -10.8px;
  background-color: var(--ourorange);
  border-radius: 0.25rem;
  height: 1.55rem;
  width: 0.7rem;
}
input[type="range"]:focus::-webkit-slider-thumb {
  outline: 2px solid var(--ourorange);
  outline-offset: 0.125rem;
  background-color: white;
}
.planner-categories-white input[type="range"]:focus::-webkit-slider-thumb {
  outline: 2px solid var(--ourorange);
  outline-offset: 0.125rem;
  background-color: var(--ourorange);
}
input[type="range"]::-moz-range-track {
  background-color: var(--ourorange);
  border-radius: 1rem;
  height: 0.2rem;
}
input[type="range"]::-moz-range-thumb {
  background-color: var(--ourorange);
  border: none;
  border-radius: 0.25rem;
  height: 1.55rem;
  width: 0.7rem;
}
input[type="range"]:focus::-moz-range-thumb {
  outline: 2px solid var(--ourorange);
  outline-offset: 0.125rem;
}

input[type="range"] + datalist option:first-child {
  text-align: left;
  padding-left: 0.35rem;
}
input[type="range"] + datalist option:last-child {
  text-align: right;
}
input[type="range"] + datalist {
  display: flex;
  margin-top: -2px;
}
input[type="range"] + datalist option {
  display: inline-block;
  width: calc((100% - 12px) / (var(--list-length) - 1));
  text-align: center;
}

.img-fluid.loading {
  --blur-amount: 6px;
  filter: blur(var(--blur-amount));
  -webkit-filter: blur(var(--blur-amount));
  -moz-filter: blur(var(--blur-amount));
  overflow: hidden;
  transform: scale(1.05);
  @media only screen and (max-width: 1200px), (min-width: 1921px) {
    transform: scale(1.05) translateX(-50%);
  }
}
.img-fluid.bg {
  display: none;
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .img-fluid {
    max-width: 100%;
    height: 100dvh;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1921px) {
  .img-fluid {
    width: 100vw;
    height: auto;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

// If you desire different fluid rules on mobile, change this
@media screen and (max-width: 768px) {
  .img-fluid {
    max-width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.content-space {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.ourBg.btn {
  border-color: transparent;
  &:hover {
    background-color: var(--ourorange);
    border-color: transparent;
  }
}

.grecaptcha-badge {
  z-index: 10;
  visibility: hidden;
}

.explanationSection {
  height: 500%;
}

/** Side Scroller Design **/

.sidescroller {
  padding-top: 2pc;
  padding-bottom: 3vh;
  .animCard {
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 40%;
    z-index: 5;
    transform-style: preserve-3d;
  }
  .animImage {
    width: 100%;
    box-shadow: #000000aa -12px 12px 2pc;
  }
  .animOverlay {
    user-select: none;
    position: absolute;
    color: black;
    width: 100%;
    text-wrap: nowrap;
  }
  .react-multi-carousel-dot button {
    border-color: white;
    background-color: transparent;
  }
  .react-multi-carousel-dot--active button {
    border-color: white;
    background-color: white;
  }
  &.dark {
    .react-multi-carousel-dot button {
      border-color: #050604;
      background-color: transparent;
    }
    .react-multi-carousel-dot--active button {
      border-color: #050604;
      background-color: #050604;
    }
  }
  &.no-top-pad {
    padding-top: 0;
  }
  &.no-bottom-pad {
    padding-bottom: 0;
  }
}

.setFootlineDown {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.thumbsuporange path {
  fill: var(--ourorange) !important;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.button-icon {
  margin-right: 0.8rem;
}

.no-break {
  white-space: nowrap;
}

.word-wrap {
  white-space: normal;
}

.justify-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.invisible {
  opacity: 0;
}

.fit-content {
  min-height: fit-content;
}

.orange-background {
  background-color: var(--ourorange);
}

