//Image Preloading
body:after {
  display: none;
  content: url("../img/icon/clipboard-check.svg");
}

.nav { // TODO: More responsive shit below 1050px
  display: flex;
  flex-direction: row;
  justify-content: space-evenly !important;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  gap: 2% !important;
  overflow-x: auto;
  overflow-y: hidden;
  border: none;
}

.headline-tourplanner {
  @extend .headline;
  color: #2f385fff;
}

.fancy_scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #2f375e #ffffff;
  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-track:hover {
    background-color: #c9c9c9;
  }
  ::-webkit-scrollbar-track:active {
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #2f375e;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #434e86;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: #2f375e;
  }
}
.fit_scroll {
  overflow-y: auto;
  height: 100%;
}
.diagonalSplit {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    125deg,
    var(--ourorange) 49.75%,
    white 50%,
    rgba(0, 0, 0, 0) 50.25%
  );
}
.reversedDiagonalSplit {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    125deg,
    rgba(0, 0, 0, 0) 49.75%,
    white 50%,
    var(--ourorange) 50.25%
  );
}

.planner-container {
  width: 90vw;
  margin-left: 5.5vw;
}

.planner-tabs {
  //background-color: rgba(0, 0, 0, 0.7);
  //border: solid 1px white;
  //border-radius: 10px 10px 0px 0px;
  margin-bottom: 0px;
  /*:hover {
    background-color: white;
  }
  :focus {
    color: white;
  }*/
  a {
    background-color: rgba(0, 0, 0, 0.7);
    border: solid 1px white !important;
    &:hover {
      background-color: white;
      border: none;
    }
  }
}
.planner-tabs.off {
  a {
    &:hover {
      cursor: not-allowed;
      background-color: rgba(0, 0, 0, 0.7);
      color: crimson !important;
    }
  }
}
/*.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
}
.planner-tabs.off.nav-item .planner-tabs-text.nav-link.active {
  background-color: initial;
}*/

.planner-tabs-spacing {
  padding-left: 12px;
  padding-right: 12px;
}

.planner-tabs-text {
  font-family: ProximaNovaBold;
  font-size: 1vw;
  color: white;
  @media screen and (max-width: 1200px) and (min-width: 768px) {
    font-size: 2vw;
    .info_icon {
      width: 32px;
      height: 32px;
      margin: 0;
      margin-left: 6px !important;
    }
    padding: 8px 4px 8px 4px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
}
.planner-tabs-text:hover {
  color: var(--darkblue);
}
.planner-tabs.off .planner-tabs-text {
  @extend .planner-tabs-text;
  color: rgb(142, 151, 144);
}
.planner-tabs.off .planner-tabs-text:hover {
  color: rgb(142, 151, 144);
}

.planner-tabs-content {
  background-color: rgba(0, 0, 0, 0.7);
  border: solid 1px white;
  border-radius: 0px 0px 10px 10px;
  height: 39vw;
}

.row_extra_style {
  height: 100%;
}

.extra_top_padding {
  padding-top: 2pc;
}

// Proposed default style for hint titles, please review.
.hint {
  width: 100%;
  height: 100%;
  padding: 2vw 2vw 0vw 2vw;
  color: white;
  h5 {
    font-family: "DeLittleChromaticBold";
    font-size: 1.25vw;
  }
  p {
    font-family: ProximaNovaLight;
    white-space: pre-wrap;
    font-size: 1.3vw;
    line-height: 1.4vw;
  }
}

// Proposed default style for summaries, please review.
.summary {
  width: 100%;
  height: 100%;
  padding: 2vw;
  color: white;
  position: relative;
  overflow-y: auto;
  h5 {
    font-family: "DeLittleChromaticBold";
    font-size: 1.25vw;
    margin-bottom: 0.5rem;
  }
  p {
    font-family: ProximaNovaLight !important;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 1.1vw !important;
    line-height: 1.2vw !important;
  }
  span.divider {
    color: gray;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  div.continuation {
    display: inline-block;
    color: black;
    background-color: var(--ourorange);
    text-align: center;
    font-size: larger;
    line-height: 4px;
    padding: 4px 4px 8px 4px;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
    &.spacing {
      margin-left: 0.5rem;
    }
    @media screen and (min-width: 1921px) {
      line-height: 8px;
      padding: 8px 8px 16px 8px;
    }
  }
}

.summaryWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  color: white;
  height: 100%;
  //flex: 0.85; // Sollte die Summary bisschen platz-sparend machen
}

.priceSummary {
  bottom: 0;
  width: 100%;
  left: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: ProximaNovaThin;
  white-space: pre-wrap;
  font-size: 1.25vw;
  .wide-col {
    flex: 2 0;
  }
  .inclusive-tag {
    float: right;
    margin-bottom: 0;
  }
}

/*** Navigation Buttons at the bottom ***/

.plan_nav_button_div {
  margin-top: 0.75em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.plan_cont_button {
  font-size: 2vw;
  font-family: ProximaNovaBold;
  color: black;
  background-color: #ff6641;
  border-color: #ff6641;
  text-transform: uppercase;
  border-radius: 1pc;
  @extend .ourBg;
}
.plan_back_button {
  font-size: 2vw;
  font-family: ProximaNovaBold;
  color: white;
  background-color: transparent;
  border-color: white;
  text-transform: uppercase;
  border-radius: 1pc;
  margin-right: 1pc;
  border: white 1px solid;
  &.btn:hover {
    background-color: white;
    color: var(--ourorange);
    border-color: transparent;
  }
}
.btn:disabled {
  background-color: transparent;
  border-color: initial;
}

/*** Categories ***/

.group_dropdown {
  z-index: 2;
  position: absolute;
  margin-top: 8px;
  //margin-left: 8px;
  min-width: 15rem;
  max-height: 20vh;
  background-color: rgba(255, 255, 255, 1.0);
  border-radius: max(0.5vw, 0.75rem);
  //border: 1px solid var(--ourorange);
  overflow-y: auto;
  padding: 0.5rem;
  p {
    text-align: center;
    width: 100%;
    color: var(--darkblue) !important;
    border-bottom: 1px solid var(--darkblue);
    margin-bottom: 0;
    padding-bottom: 4px;
    margin-top: 4px;
    font-family: ProximaNovaLight !important;
    font-size: 1.1vw !important;
    line-height: initial !important;
    transition: all ease-in-out 200ms;
    cursor: pointer;
    &.deactivated {
      color: gray !important;
      cursor: not-allowed;
    }
  }
  p:last-child {
    border-bottom: none;
  }
  p:hover {
    background-color: rgba($color: #fff, $alpha: 0.15);
  }
  opacity: 0;
  display: none;
  transition: opacity ease-in-out 200ms;
  box-shadow: 3px 3px 12px 2px #000000;
}
.group_dropdown.hover {
  display: inherit;
  opacity: 1;
}

.cat_opt_row {
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  > div {
    flex: 1;
  }
  .split {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    > p {
      margin-left: 12px;
    }
  }
  @media screen and (min-width: 2000px) {
    .info_icon {
      margin-left: 32px;
      --box-size: 48px;
    }
  }
}

.cat_1 {
  width: 100%;
  margin: 0;
  padding: 0;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.1fr 0.1fr;
  cursor: pointer;
  p {
    font-family: ProximaNovaBold !important;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0 !important;
    cursor: pointer;
  }
  p:nth-last-child(1) {
    font-size: 1vw !important;
  }
  p:nth-last-child(2) {
    font-size: 1vw !important;
  }
  @media screen and (max-width: 768px) {
    p:nth-last-child(1) {
      font-size: 4vw !important;
    }
    p:nth-last-child(2) {
      font-size: 4vw !important;
    }
  }
  &.diagonalSplit {
    :nth-child(1) {
      color: var(--darkblue);
    }
    :nth-child(3) {
      color: var(--darkblue);
    }
  }
  &.reversedDiagonalSplit {
    :nth-last-child(1) {
      color: var(--darkblue);
    }
    :nth-last-child(3) {
      color: var(--darkblue);
    }
  }
}
.diagCut {
  width: 100%;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.1fr 0.1fr;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  p {
    font-family: ProximaNovaBold !important;
    font-size: 2rem;
    text-align: center;
    line-height: 0;
    padding: 2rem;
    flex: 1 1;
    text-align: center;
    cursor: pointer;
  }
  &.diagonalSplit {
    p:nth-child(1) {
      color: var(--darkblue);
    }
  }
  &.reversedDiagonalSplit {
    p:nth-last-child(1) {
      color: var(--darkblue);
    }
  }
}

.planner-categories {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  background-color: transparent;
  padding-top: 0.5pc;
  padding-bottom: 0.5pc;
}

.planner-categories-white {
  @extend .planner-categories;
  background-color: rgb(255, 255, 255);
  padding-top: 0.5pc;
  padding-bottom: 0.5pc;
}

.planner-categories p {
  font-family: "DeLittleChromaticBold";
  color: rgb(255, 255, 255);
  margin-bottom: 0px;
  font-size: 1.5vw;
}

.planner-categories-white p {
  color: #2f385fff;
}

.planner-categories.bottom-border {
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
}
.planner-categories.top-border {
  border-top: 2px solid rgb(255, 255, 255);
}

.theme-categories-text {
  .box {
    display: flex;
    flex-direction: row;
    border: var(--ourorange) 1px solid;
    border-radius: max(0.25vw, 0.3rem);
    background-color: transparent;
    input {
      border: none;
      background-color: transparent;
      color: white;
      text-align: center;
      font-size: 1.1vw;
      font-family: ProximaNovaLight;
      padding: 4px 8px 4px 4px;
    }
    img {
      width: 24px;
      border-left: var(--ourorange) 1px solid;
      padding-left: 8px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  input:focus {
    color: white;
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
  }
}

.theme-dropdown-box {
  background-color: transparent;
  border: 2px solid var(--ourorange);
  border-radius: var(--bs-border-radius);
  text-align: center;
  cursor: pointer;
  transition: background-color ease-in-out 100ms;
  .box {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    > p {
      font-size: 1.1vw;
      font-family: ProximaNovaLight;
      cursor: pointer;
      padding: 4px;
      width: 100%;
    }
    img {
      width: 24px;
      border-left: var(--ourorange) 1px solid;
      padding-left: 8px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1200px) {
    min-width: 8rem;
  }
  &:focus-visible {
    border-color: black;
    img {
      border-left-color: #000000;
    }
  }
}
.theme-dropdown-box:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/*** Pick Riddle ***/

.riddlerow {
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25pc;

  p {
    color: white;
    font-family: ProximaNovaBold;
    font-size: 1.4vw;
    margin-bottom: 0;
  }
}

.riddlecard {
  background-color: white;
  border-radius: 1rem;
  width: 65%;
  height: fit-content;
  text-align: center;
  overflow: hidden;
  margin-left: 4px;
  margin-right: 4px;
  background-image: url("../img/icon/Info.svg");
  background-repeat: no-repeat;
  background-size: 1vw;
  background-position: right 16px top 16px;
  cursor: pointer;

  p {
    width: 80%;
    margin: auto;
    color: var(--darkblue);
    font-family: "DeLittleChromaticBold";
    font-size: 1.5vw;
    line-height: 1.5vw;
    margin-bottom: 0;
    word-break: break-word;
    cursor: pointer;
    &.smaller {
      font-size: 1.25vw;
      line-height: 1.25vw;
    }
  }

  .band {
    background-color: black;
    position: relative;
    transform: rotate(-40deg) translateX(-2pc) translateY(-1pc);
    width: 7pc;
    height: 0.8pc;
  }
  .band.easy {
    background-color: #359ee3;
  }
  .band.normal {
    background-color: #00bc48;
  }
  .band.hard {
    background-color: #c3250a;
  }

  .attr {
    margin-top: 0.7vw;
    margin-bottom: 0.2vw;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    span {
      color: var(--darkblue);
      font-family: ProximaNovaLight;
      font-size: 1vw;
      border: 1px solid var(--darkblue);
      border-radius: 0.3rem;
      padding: 0px 4px 0px 4px;
      margin-left: 4px;
      margin-right: 4px;
      margin-bottom: 4px;
      &.dots {
        font-weight: bolder;
      }
      &.preparation {
        color: var(--ourorange);
        font-family: ProximaNovaLight;
        font-size: 1vw;
        border: 1px solid var(--ourorange);
        border-radius: 0.3rem;
        padding: 0px 4px 0px 4px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

.riddleArrow {
  height: 1vw;
  @media screen and (max-width: 900px) {
    height: 4vw;
  }
  @media screen and (max-width: 768px) {
    height: 6vw;
  }
  transition: all 150ms ease-out;
  cursor: pointer;
}
.riddleArrow.left {
  transform: rotate(90deg);
}
.riddleArrow.right {
  transform: rotate(-90deg);
}
.riddleArrow.disabled {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.75;
  cursor: not-allowed;
}
.riddleArrow:hover {
  scale: 1.25;
}
.riddleArrow.disabled:hover {
  opacity: 0.5;
  scale: 1;
}

.TaskCard {
  width: 100%;
  min-height: 48%;
  max-height: 75%;
  overflow: hidden;
  border-radius: 1pc;
  background-color: white;
  color: var(--darkblue);
  margin-bottom: 16px;
  > :nth-child(2) {
    font-family: "DeLittleChromaticBold" !important;
    font-size: 2vw;
    text-align: center;
    margin: auto;
    margin-top: 1vw;
    margin-bottom: 3vw;
    line-height: 2vw;
    word-break: break-word;
    width: 70%;
  }
  > :nth-child(3) {
    display: flex;
    width: 100%;
    height: fit-content;
    padding-left: 2vw;
    padding-right: 2vw;
    > :nth-child(1) {
      display: none;
      flex: 1;
      aspect-ratio: 1;
      object-fit: cover;
      width: 20%;
      height: 20%;
      border-radius: 1pc;
    }
    > :nth-child(2) {
      flex: 1.5;
      font-family: ProximaNovaThin;
      white-space: pre-wrap;
      font-size: 1vw;
      line-height: 1.2vw;
      margin-left: 0.5pc;
    }
  }
  .band {
    background-color: black;
    position: relative;
    text-align: center;
    width: 15rem;
    color: white;
    font-family: ProximaNovaBold;
    font-size: larger;
    line-height: 1rem;
    height: 2.5pc;
    padding-left: 4rem;
    padding-right: 4rem;
    transform: rotate(-30deg) translateX(-4pc) translateY(-1pc);
  }
  .band.easy {
    background-color: #359ee3;
    color: black;
    padding-top: 0.2rem;
  }
  .band.normal {
    background-color: #00bc48;
    padding-top: 0.6rem;
  }
  .band.hard {
    background-color: #c3250a;
    padding-top: 0.1rem;
  }

  .attr {
    margin: auto;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 85%;
    text-align: center;
    justify-content: center;
    align-content: center;
    span {
      color: var(--darkblue);
      font-family: ProximaNovaLight;
      font-size: 1vw;
      border: 1px solid var(--darkblue);
      border-radius: 0.3rem;
      padding: 0px 4px 0px 4px;
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 8px;
      &.preparation {
        color: var(--ourorange);
        font-family: ProximaNovaLight;
        font-size: 1vw;
        border: 1px solid var(--ourorange);
        border-radius: 0.3rem;
        padding: 0px 4px 0px 4px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

.preparationTag {
  color: var(--ourorange) !important;
  font-family: ProximaNovaLight !important;
  line-height: normal !important;
  font-size: 1vw !important;
  border: 1px solid var(--ourorange) !important;
  border-radius: 0.3rem !important;
  padding: 0px 4px 0px 4px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
  margin-bottom: 4px !important;
  @media screen and (max-width: 768px) {
    font-size: 5vw !important;
  }
}

.spinner-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pick-riddle-summary-table {
  border-collapse: collapse;
  --borders: white 1px solid;
  tr {
    font-size: 1.2vw;
    line-height: 1.3vw;
    @media screen and (max-width: 768px) {
      font-size: 4vw;
      line-height: 4.6vw;
    }
    th {
      text-align: left;
      padding-right: 8px;
      &:nth-child(1) {
        text-align: center;
        padding-left: 8px;
      }
      &:last-child td {
        border-bottom: 0;
      }
    }
    td {
      border-top: var(--borders);
      border-bottom: var(--borders);
      &:first-child {
        text-align: center;
      }
    }
  }
}

/*** Positioning ***/
.positioncontainer {
  h5 {
    font-family: "DeLittleChromaticBold";
  }
  .hintP {
    font-family: ProximaNovaLight;
    white-space: pre-wrap;
    font-size: 1.2vw;
    line-height: 1.4vw;
  }
  p {
    font-family: ProximaNovaThin;
    white-space: pre-wrap;
    font-size: large;
  }
  

  .container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .enumeration {
    width: 5.5vw;
    vertical-align: middle;
    display: flex;
    align-items: center;
    font-size: 1vw;
    font-weight: 600;
  }

  .waypoint-description {
    border-radius: 0.5rem;
    border-width: 1px;
    background-color: white;
    color: var(--darkblue);
    padding: 0.5rem;
    cursor: pointer;
  }

  .alreadyPlaced {
    cursor: not-allowed;
    opacity: 0.7;
  }

  .waypoint-name {
    color: #2f385f;
    font-family: "DeLittleChromaticBold";
    font-size: 1.2vw;
    text-align: center;
    word-break: break-word;
  }
  .attribute-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .attribute {
    border: 1px var(--darkblue) solid;
    color: var(--darkblue);
    border-radius: 5px;
    padding: 3px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: center;
    box-sizing: border-box;
    font-size: 0.8em;
  }

  .marker-container {
    width: 50px;
  }

  .marker-area {
    width: 100%;
    height: 100%;
    /*because border style is very specific*/
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='white' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='6' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 5px;
    position: relative;
  }

  .waypoint-description {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .resetButton {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .resetButton span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    font-family: "DeLittleChromaticBold";
  }

  .hide {
    display: none;
  }

  .pac-card {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 0 0.5em;
    font: 400 18px Roboto, Arial, sans-serif;
    overflow: hidden;
    font-family: Roboto;
    padding: 0;
  }

  #pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
  }

  .pac-controls {
    display: inline-block;
    padding: 5px 11px;
  }

  .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }

  #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    height: 40px;
    width: 180px;
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  .currentDistance {
    font-weight: bold;
    font-family: ProximaNovaBold;
  }

  .recommendedDistance {
    margin-top: 20px;
    font-family: ProximaNovaLight;
    white-space: pre-wrap;
    font-size: large;
  }

  .waypointWrapper {
    margin-top: 50px;
    .row {
      flex-wrap: nowrap;
    }
    .positionwaypoint {
      padding-right: 0;
      padding-left: 8px;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background-color: #2f385f59;
    border: 2px solid #2f385f;
    border-radius: 50%;
    margin-right: 10px;
  }

  .circleDescription {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ProximaNovaThin;
    white-space: pre-wrap;
    font-size: large;
  }

  @media screen and (min-width: 2000px) {
    .circleDescription {
      font-size: 1vw;
    }
    .currentDistance {
      font-size: 1vw;
    }
    p {
      font-size: 1vw;
    }
  }
}

/*** Buchung ***/
.bookingcontainer {

  h5 {
    font-family: "DeLittleChromaticBold";
  }
  p {
    font-family: ProximaNovaLight;
    font-size: 1.2vw;
    line-height: 1.4vw;
    white-space: pre-wrap;

  }

  .adress-col {
    overflow-y: auto;
  }

  .wrapper{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }

  .shipping-adress-form {
    margin-top: 15px;
  }

  .billing-adress-form {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .order-response-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .order-result-icon {
    width: 120px;
  }

  .response-title {
    font-family: ProximaNovaBold;
    font-size: 3rem;
    margin-top: 20px;
  }

  .response-description {
    font-family: ProximaNovaLight;
    font-size: 1.6rem;
  }

  }

/*** Map ***/
#map {
  width: 100%;
  aspect-ratio: 1;
}
#desc {
  float: left;
  margin: 0 25px 10px 20px;
  width: 10em;
}
#markers {
  position: absolute;
  width: 200px;
  height: 110px;
  background-color: red;
}
.placed {
  display: none;
}
.drag {
  --size: 32px;
  position: absolute;
  width: var(--size);
  height: var(--size);
  background-image: url("../img/icon/GPS_filled_selected.svg");
  background-repeat: no-repeat;
  background-position: center center;
  left: calc(50% - var(--size) / 2);
  top: calc(50% - var(--size) / 2);
  cursor: pointer;
  font-family: "DeLittleChromaticBold";
  color: var(--darkblue);
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

.infowindow {
  margin-top: 20px;
  width: 180px;
  height: 60px;
}

/*** Overview ***/
.overviewcontainer {
  h5 {
    font-family: "DeLittleChromaticBold";
    font-size: 1.25vw;
    @media screen and (max-width: 768px) {
      font-size: 5.2vw;
    }
  }
  p {
    font-family: ProximaNovaLight;
    white-space: pre-wrap;
    font-size: 1.2vw;
    line-height: 1.4vw;
    &.spacing {
      margin-bottom: 2pc;
    }
  }

  .save-box {
    padding: 20px;
    text-align: center;
  }

  .save-box .col {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .info-box {
    padding: 20px;
  }

  .save-button {
    font-family: ProximaNovaBold;
    color: black;
    background-color: #ff6641;
    border-color: #ff6641;
    margin-top: 0.5pc;
    font-size: 1.2vw;
    border-radius: 0.8pc;
    width: 30%;
    @extend .ourBg;
    @media screen and (max-width: 768px) {
      font-size: 4vw;
    }
  }

  .save-button:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }

  .tour-name-input {
    font-family: ProximaNovaLight;
    background-color: transparent;
    color: white;
    border-color: var(--ourorange);
    padding: 8px;
    box-sizing: border-box;
    padding-left: 16px;
    width: 50%;
    font-size: 1vw;
    border-radius: 0.8pc;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .tour-name-input::placeholder {
    opacity: 0.6;
    color: white;
  }

  .error-message {
    margin-top: 0.75pc;
    font-family: ProximaNovaThin;
    white-space: pre-wrap;
    font-size: 1vw;
    line-height: 1.1vw;
    color: rgba(var(--bs-danger-rgb));
    @media screen and (max-width: 768px) {
      font-size: 5vw;
      line-height: 5.1vw;
    }
  }

  .codeDisplay {
    font-family: ProximaNovaLight;
    border: var(--bs-border-width) solid var(--ourorange);
    background-color: transparent;
    color: white;
    padding: 8px;
    box-sizing: border-box;
    width: 35%;
    min-height: 2.2rem;
    border-radius: max(var(--bs-border-radius), 1rem);
    text-align: center;
    font-size: 1.5vw;
    font-weight: bold;
  }

  .shareButtons {
    margin-top: 15px;
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .shareButtons button {
    color: white;
    text-align: center;
    font-size: unset;
    width: fit-content;
    height: fit-content;
    i {
      font-size: 1.6vw;
      margin-left: -2px;
    }
    background-color: #ff6641;
    border-color: #ff6641;
    &:hover {
      color: black;
    }
  }

  .tourCodeInfoBox {
    border: var(--bs-border-width) solid var(--ourorange);
    margin-top: 25px;
    border-radius: 1pc;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 6px;
  }

  .tourCodeDesc {
    font-family: ProximaNovaLight;
    white-space: pre-wrap;
    font-size: 1vw;
    line-height: 1.3vw;
    margin: 0;
    text-align: justify;
    padding-right: 1pc;
  }
}

.orange {
  color: var(--ourorange);
}

.overviewSummary {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1em;
}

.summaryRow {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: baseline;
}

.summaryRowContent {
  font-family: ProximaNovaLight;
  font-size: 1.3em;
  font-weight: bold;
  display: inline-block;
  margin-left: 15px;
}

.reserveSummary {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

/*** Reservation ***/

.reservecontainer {
  h5 {
    font-family: "DeLittleChromaticBold";
    font-size: 1.25vw;
  }
  p {
    font-family: ProximaNovaLight;
    white-space: pre-wrap;
    font-size: 1.2vw;
    line-height: 1.3vw;
  }

  .date-box {
    padding: 20px;
    text-align: center;
  }

  .playercount-box {
    padding: 20px;
    text-align: center;
    margin: auto;
  }

  .save-box {
    padding-top: 20px;
    text-align: center;
  }

  .save-box .col {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .info-box {
    padding: 20px;
  }

  .save-button {
    font-family: ProximaNovaBold;
    color: black;
    background-color: #ff6641;
    border-color: #ff6641;
    font-size: 1.2vw;
    @extend .ourBg;
  }

  .save-button:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }

  .email-input {
    font-family: ProximaNovaLight;
    background-color: transparent;
    color: white;
    border-color: var(--ourorange);
    padding: 8px;
    box-sizing: border-box;
    width: 20rem;
    height: 2.25vw;
    font-size: 1.25vw;
    @media screen and (min-width: 1200px) and (max-height: 1000px) {
      height: 3vw;
    }
  }

  .email-input::placeholder {
    opacity: 0.6;
    color: white;
  }

  .error-message {
    font-family: ProximaNovaThin;
    white-space: pre-wrap;
    font-size: 1vw;
    color: rgba(var(--bs-danger-rgb));
  }

  .react-datepicker__view-calendar-icon input {
    font-family: ProximaNovaLight;
    border: var(--bs-border-width) solid var(--ourorange);
    background-color: transparent;
    color: white;
    padding: 8px;
    box-sizing: border-box;
    width: 12vw;
    height: 2.2vw;
    font-size: 1vw;
    border-radius: var(--bs-border-radius);
  }

  .react-datepicker__view-calendar-icon input:focus-visible {
    border: var(--bs-border-width) solid var(--ourorange);
    outline: none;
  }

  .react-datepicker__view-calendar-icon input::placeholder {
    opacity: 0.6;
    color: white;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.5rem;
    right: 5px;
    color: var(--ourorange);
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
    width: 1.5vw;
    border-left: var(--bs-border-width) solid var(--ourorange);
    @media screen and (max-width: 1640px) {
      width: 2vw;
    }
    @media screen and (max-width: 768px) {
      font-size: 5vw;
      height: 8vw;
      width: 6vw;
      padding: 0;
      padding-left: 5px;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--ourorange);
    color: #fff;
  }

  .reserved-until-date {
    font-weight: 600;
    color: rgba(var(--bs-danger-rgb));
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  .codeDisplay {
    font-family: ProximaNovaLight;
    border: var(--bs-border-width) solid var(--ourorange);
    background-color: transparent;
    color: white;
    padding: 8px;
    box-sizing: border-box;
    width: 150px;
    height: 1.75vw;
    border-radius: var(--bs-border-radius);
    text-align: center;
    font-size: 1.5vw;
    font-weight: bold;
  }

  .downloadHeading {
    margin-top: 30px;
  }

  /*
  .before-save {
    // Email Inputbox in "Reservation" Section
  }
  */

  .after-save {
    width: 90%;
  }

  .mail-sucess-message {
    color: #00bc35;
  }

  .error-color {
    color: rgba(var(--bs-danger-rgb)) !important;
  }

  .material-not-available {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1.5vw;
    font-weight: 400;
    line-height: 1vw;
    color: var(--ourorange);
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: var(--bs-border-width) solid var(--ourorange);
    border-radius: var(--bs-border-radius);
    @media screen and (max-width: 768px) {
      font-size: 80%;
    }
  }
}

/*** ErrorModal ***/
.modal-header {
  font-family: "DeLittleChromaticBold";
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}

.modal-content {
  background-color: rgba(47, 56, 95, 0.9) !important;
  color: white !important;
  font-family: ProximaNovaLight;
  white-space: pre-wrap;
  font-size: 1.5rem;
  line-height: 1.75rem;
  border-radius: 2pc;
  .topright {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.modalOkButton {
  background-color: var(--ourorange);
  border: var(--ourorange);
  font-size: ProximaNovaBold;
  font-size: 1rem;
  color: black;
}
.btn-close {
  opacity: 1;
  filter: brightness(0) saturate(100%) invert(56%) sepia(61%) saturate(3315%) hue-rotate(333deg) brightness(100%) contrast(102%);
}

/*** Error Alert ***/
.errorAlert {
  width: calc(100% - 10px);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: "ProximaNovaLight";
  font-size: 1.5vw;
  margin: 5px;
  @media screen and (min-width: 1921px) {
    font-size: 1vw;
  }
}

.blockingError {
  background-color: var(--ourorange);
}

.nonBlockingError {
  background-color: #ffc107;
}

.errorAlertIcon {
  margin-right: 10px;
  @media screen and (max-width: 768px) {
    width: 6vw;
    font-size: 4vw;
  }
}

/*** Get Started ***/

.getstartedcontainer {
  font-family: ProximaNovaBold;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
}
.getstartedcontainer .code_entry {
  flex-direction: row;
  width: 100%;
}
@keyframes blinkButton {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(5%);
  }

  100% {
    transform: translateY(-10%);
  }
}
.getstartedcontainer .code_entry button {
  font-size: 1.2vw;
  width: 100%;
  color: black;
  background-color: #ff6641;
  border-color: #ff6641;
  text-transform: uppercase;
  transition: all ease-in-out 100ms;
  animation: blinkButton 200ms ease-in-out 0s 1 normal forwards;
  @media screen and (max-width: 1280px) and (min-width: 768px) {
    font-size: 1.25vw;
  }
}
.getstartedcontainer .offset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.7em;
  padding-right: 0.7em;
  position: relative;
  text-align: center;
  height: 100%;
  flex: 1;
  .or {
    font-size: 1.25vw;
    margin-bottom: 8px;
  }
  > p {
    color: white;
    font-size: 1.5vw;
    margin-bottom: 16px;
    line-height: 1em;
    user-select: none;
  }
  .code_entry {
    .nextToEachOther {
      display: flex;
      flex-direction: row;
      margin-bottom: 1pc;
      @media screen and (max-width: 1280px) {
        margin-bottom: 0px;
      }
      justify-content: center;
      > div > p {
        text-align: left;
        margin-bottom: 0;
        font-size: 1.1rem;
        color:rgba(255, 255, 255, 0.75);
      }
      > div:nth-child(1) {
        margin-right: 12px;
      }
      .textinput {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: var(--ourorange) 2px solid;
        border-radius: 8px;
        img {
          margin-left: 8px;
          padding-right: 8px;
          width: 32px;  
          height: 32px;
          @media screen and (min-width: 2000px) {
            width: 48px;
            height: 48px;
          }
        }
      }
      input {
        text-align: left;
        font-size: 50%;
        width: 100%;
        height: 2.5rem;
        @media screen and (max-width: 1280px) {
          font-size: 30%;
          height: 2rem;
        }
        color: white;
        background-color: transparent;
        outline: none;
        border: none;
        padding: 8px;
        padding-right: 0;
        box-shadow: none;
      }
    }
    button.locked {
      background-color: #787878;
      border-color: #787878;
      animation: none;
    }
  }
  @media screen and (min-width: 1921px) {
    padding-left: 1em;
    padding-right: 1em;
    .or {
      font-size: 1.5vw;
      margin-bottom: 8px;
    }
    > p {
      font-size: 2vw;
      margin-bottom: 16px;
      line-height: 1em;
    }
    .code_entry {
      .nextToEachOther {
        > div > p {
          font-size: 1.1vw;
        }
        > div:nth-child(1) {
          margin-right: 32px;
        }
        input {
          text-align: left;
          font-size: 1.25vw;
          width: 100%;
          height: 2vw;
          color: white;
          background-color: transparent;
          outline: none;
          border: none;
          padding: 8px;
          padding-right: 0;
          box-shadow: none;
        }
      }
    }
  }
}
.getstartedcontainer .flexing {
  flex: 3;
}

.getStartedTooltip {
  z-index: 6;
  position: fixed;
  left: 0;
  top: 0;
  min-width: 14vw;
  opacity: 0;
  scale: 0;
  transition: opacity ease-out 125ms;
  &.show {
    scale: 1;
    opacity: 1;
  }
  &:hover {
    scale: 1;
    opacity: 1;
  }
  > div {
    position: absolute;
    left: 0;
    top: 10%;
    padding: 0 0.5vw 0 0.5vw;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  p {
    font-family: ProximaNovaLight;
    width: 80%;
    margin: auto;
    position: relative;
    font-size: 1vw;
    line-height: 1.2vw;
    text-align: left;
    color: white;
  }
}

.getstartedbutton {
  position: relative;
  border-radius: 1pc;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #2f385f;
  background-color: white;
  border-color: white;
  font-size: 1.5vw;
  @media screen and (min-width: 1921px) {
    font-size: 1.5vw;
    border-radius: 0.5vw;
  }
  @extend .ourBg
}

.getstartedcontainer .animations {
  display: flex;
  flex-direction: row;
  margin-top: 1pc;
  margin-bottom: 1pc;
  width: 100%;
  height: fit-content;
  text-align: center;
  align-items: center;
  justify-content: center;
  .animCard {
    user-select: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 5;
    transform-style: preserve-3d;
    --cardspacing: 2rem;
    --brightness: 0.5;
  }
  .animCard.fadeIn {
    position: absolute;
    scale: 0.75;
    z-index: 3;
    filter: brightness(var(--brightness));
    width: 0px;
  }
  .animCard.hidden {
    opacity: 0;
  }
  .animImage {
    width: 100%;
    box-shadow: black 0px 0px 12px;
    &.loading {
      background-color: #383838;
      color: white;
      height: 412px;
      line-height: 400px;
      @media screen and (max-width: 1200px) {
        height: 316px;
        line-height: 300px; 
      }
      &::after {
        content: "";
        animation: loadingtext 1s infinite;
      }
      @keyframes loadingtext {
        0% { content: "."; }
        25% { content: ".."; }
        50% { content: "..."; }
        86% { content: "..."; }
        90% { content: ".."; }
        94% { content: "."; }
        98% { content: ""; }
      }
    }
  }
  .animOverlay {
    user-select: none;
    position: absolute;
    color: black;
    width: 100%;
    text-wrap: nowrap;
    transform: translateZ(30px);
  }
  .animCardsHolder {
    --fixed-size: 80%;
    min-width: var(--fixed-size);
    max-width: var(--fixed-size);
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform-style: preserve-3d;
    .animCard:nth-child(odd) {
      width: 75%;
      filter: brightness(var(--brightness));
      //transform: rotateX(calc(var(--posY) * -10deg)) rotateY(calc(var(--posX) * 10deg));
    }
    .animCard:nth-child(2) {
      margin-left: var(--cardspacing);
      margin-right: var(--cardspacing);
      //transform: rotateX(calc(var(--posY) * -25deg)) rotateY(calc(var(--posX) * 25deg));
    }
  }
}

.getStartedArrow {
  cursor: pointer;
  width: 5%;
  transition: all 150ms ease-out;
  z-index: 7;
}
.getStartedArrow.left {
  transform: rotate(90deg);
}
.getStartedArrow.right {
  transform: rotate(-90deg);
}
.getStartedArrow.disabled {
  cursor: not-allowed;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.75;
}
.getStartedArrow:hover {
  scale: 1.25;
}
.getStartedArrow.disabled:hover {
  opacity: 0.5;
  scale: 1;
}

.clipboardButton {
  background-image: url("../img/icon/clipboard.svg");
  width: 15px;
  height: 15px;
  border: none;
  background-color: transparent;
  color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  outline: none;
}

.clipboardButtonChecked {
  background-image: url("../img/icon/clipboard-check.svg");
}

.pointer {
  cursor: pointer;
}

.inlineClipboardButton {
  font-family: "PROXIMANOVALIGHT";
  color: black;
  background-color: #ff6641;
  border-color: #ff6641;
  font-size: 0.8vw;
  padding-top: 2px;
  padding-left: 5px;
  padding-bottom: 2px;
  padding-right: 5px;
  margin-left: 5px;
  @extend .ourBg;
  @media screen and (min-width: 1921px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: 768px) {
    font-size: 4vw;
    padding-left: 15px;
    padding-right: 15px;
    i {
      font-size: 4vw !important;
    }
  }
}

.white-link {
  color: white;
}

.payment-spacing {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.summary-light{
  font-family: 'ProximaNovaLight';
  font-size:  1.15vw;
  color: #cfcfcf;
}

.summary-group-description{
  margin-left: 20px;
  font-style: italic;
  font-size: 1vw;
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  }
  margin-top: -5px;
}

.summary-devider {
  border: 0;
  border-top: 1px solid #fff;
  margin-bottom: 5px;
  margin-top: 5px;
  opacity: 1;
}

.attribute-legend {

  .legend-attr {
    display: inline-block;
    span {
      color: white;
      font-family: ProximaNovaLight;
      font-size: 0.75vw;
      line-height: 1vw;
      border: 1px solid white;
      border-radius: 0.3rem;
      padding: 0px 4px 0px 4px;
      margin-right: 4px;
      white-space: nowrap;
    }

    .preparation {
      color: var(--ourorange);
      font-family: ProximaNovaLight;
      font-size: 0.75vw;
      border: 1px solid var(--ourorange);
      border-radius: 0.3rem;
      padding: 0px 4px 0px 4px;
      margin-right: 4px;
    }
  }
  
  .legend-entry {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
  }

  .equal {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 0.7vw;
  }

  .description {
    font-family: ProximaNovaLight !important;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 1.1vw !important;
    line-height: 1.2vw !important;
  }

  .attr-wrapper {
    //min-width: 130px; -> Sorgt für Probleme bei langen Texten
  }


}

.cookieError {
  font-family: "ProximaNovaBold";
  font-size: 1em;
  font-weight: bold;
  color: rgba(var(--bs-danger-rgb));
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}